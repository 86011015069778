// import React from 'react'
import Layout from "../Layout";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const FAQItem = ({ question, answer }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className='mb-4'>
      <button
        className='flex justify-between w-full px-4 py-2 text-left bg-green-200 rounded-md hover:bg-gray-300 focus:outline-none '
        onClick={toggleAccordion}>
        <span className='font-bold'>{question}</span>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          className={`h-6 w-6 transition-transform transform ${
            isOpen ? "rotate-180" : "rotate-0"
          }`}
          fill='none'
          viewBox='0 0 24 24'
          stroke='currentColor'>
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
            d='M19 9l-7 7-7-7'
          />
        </svg>
      </button>
      {isOpen && (
        <div className='p-4 bg-gray-100 rounded-md'>
          <p>{answer}</p>
        </div>
      )}
    </div>
  );
};

const FAQList = ({ faqs }) => {
  return (
    <div>
      {faqs.map((faq, index) => (
        <FAQItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </div>
  );
};

const FAQSection = () => {
  const { t } = useTranslation();

  const faqs = [
    {
      question: t("Faq Q1"),
      answer: t("Faq A1"),
    },
    {
      question: t("Faq Q2"),
      answer: t("Faq A2"),
    },
    {
      question: t("Faq Q3"),
      answer: t("Faq A3"),
    },
    {
      question: t("Faq Q4"),
      answer: t("Faq A4"),
    },
    {
      question: t("Faq Q5"),
      answer: t("Faq A5"),
    },
    {
      question: t("Faq Q6"),
      answer: t("Faq A6"),
    },
    {
      question: t("Faq Q7"),
      answer: t("Faq A7"),
    },
    {
      question: t("Faq Q8"),
      answer: t("Faq A8"),
    },
    {
      question: t("Faq Q9"),
      answer: t("Faq A9"),
    },
    {
      question: t("Faq Q10"),
      answer: t("Faq A10"),
    },

    // Add more FAQs here...
  ];

  return (
    <Layout>
      <div className='container mx-auto py-8 px-4'>
        <h2 className='text-green-600 text-center text-3xl font-bold mb-6'>
          {t("Frequently Asked Questions")}
        </h2>
        <FAQList faqs={faqs} />
      </div>
    </Layout>
  );
};

export default FAQSection;
