import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  IoAddCircle,
  IoTrashBinSharp,
  IoCaretDownSharp,
  IoCaretForwardSharp,
} from "react-icons/io5";
import DatePicker from "react-datepicker";
import emailjs from "@emailjs/browser";
import { ThreeDots } from "react-loader-spinner";

import "react-datepicker/dist/react-datepicker.css";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../utils/firebase";
import { useTranslation } from "react-i18next";

// import { defaultRequestObj, checkInputValidation } from "../utils";

const colRef = collection(db, "cleaningRequests");

const camelCaseToText = (str) => {
  const text = str.replace(/([A-Z])/g, " $1").trim();
  return text.charAt(0).toUpperCase() + text.slice(1);
};

const validateEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
};

const containsOnlyDigits = (input) => {
  const re = /^\d+$/;
  return re.test(input);
};

const validatePhoneNumber = (phoneNumber) => {
  // Regular expression for common phone number formats
  const re = /^[\d ()+-]+$/;
  return re.test(phoneNumber);
};

const validateInputs = (item) => {
  let errors = [];
  const isNumber = item.type === "number";
  if (!item.required && !item.validation) return errors;
  if (!item.value && item.validation && item.required)
    errors.push("This field is mandatory");

  if (item.value && item.value.length < item.min)
    errors.push(`Enter at least ${item.min} characters`);
  else if (item.max && item.value.length > item.max)
    errors.push(`Enter max. characters ${item.max}`);

  if (item.name === "email" && item.value && !validateEmail(item.value))
    errors.push("Not valid email-Address");

  if (item.name === "phone" && item.value && !validatePhoneNumber(item.value))
    errors.push("Not valid mobile number");

  if (isNumber && item.value && !containsOnlyDigits(item.value))
    errors.push("Not valid number characters");
  return errors;
};

const checkInputValidation = (item) => {
  const inputs = item.inputs.map((i) => {
    return validateInputs(i).length;
  });
  return inputs.reduce((acc, val) => acc + val, 0);
};

const defaultRequestObj = [
  {
    title: "General Cleaning",
    name: "general-cleaning",
    inputs: [
      {
        name: "reason",
        type: "select",
        label: "Select reason for cleaning",
        required: true,
        value: "",
        options: [
          "Normal cleaning",
          "Final cleaning",
          "Moving cleaning",
          "By craftsman",
          "Spring cleaning",
          "Construction cleaning",
          "Heavily dirty object",
          "After water damage",
          "Messy apartment",
          "Remove pigeon droppings",
          "Fire cleaning",
          "Clean wooden terrace",
          "Catering cleaning",
          "Remove nicotine smell",
          "Clearing out and disposal",
          "Disinfectant cleaning",
        ],
        min: 3,
        validation: true,
      },
      {
        name: "areaInSqm",
        type: "number",
        label: "Area in sqm",
        required: true,
        value: "",
        min: 1,
        validation: true,
      },
      {
        name: "degreePollution",
        type: "select",
        label: "Degree of pollution",
        required: true,
        value: "",
        options: ["light", "normal", "strong", "extreme"],
        min: 3,
        validation: true,
      },
      {
        name: "additionalInformation",
        type: "textarea",
        label: "Additional Information",
        required: false,
        value: "",
      },
    ],
  },
  {
    title: "Window Cleaning",
    name: "window-cleaning",
    inputs: [
      {
        name: "reason",
        type: "select",
        label: "Select reason for cleaning",
        required: true,
        value: "",
        options: ["glass cleaning", "Clean the Winter garden"],
        min: 3,
        validation: true,
      },
      {
        name: "windowNo",
        type: "number",
        label: "No of windows",
        required: true,
        value: "",
        min: 1,
        validation: true,
      },
      {
        name: "maxRoomHeight",
        type: "text",
        label: "max. room height (e.g. 2.5m or 4m)",
        required: true,
        value: "",
        min: 3,
        validation: true,
      },
      {
        name: "degreePollution",
        type: "select",
        label: "Degree of pollution",
        required: true,
        value: "",
        options: ["light", "normal", "strong", "extreme"],
        min: 3,
        validation: true,
      },
      {
        name: "additionalInformation",
        type: "textarea",
        label: "Additional Information",
        required: false,
        value: "",
      },
    ],
  },
  {
    title: "Furniture Cleaning",
    name: "furniture-cleaning",
    inputs: [
      {
        name: "objectType",
        type: "select",
        label: "Select Object type",
        required: true,
        value: "",
        options: [
          "House",
          "Apartment",
          "Office",
          "Practice",
          "Load",
          "Restaurant",
        ],
        min: 3,
        validation: true,
      },
      {
        name: "floor",
        type: "text",
        label: "floor (e.g. ground floor or 4th floor)",
        required: true,
        value: "",
        min: 3,
        validation: true,
      },
      {
        name: "furnitureType",
        type: "select",
        label: "Type of furniture",
        required: true,
        value: "",
        options: ["furnished", "partially furnished", "unfurnished"],
        min: 3,
        validation: true,
      },
      {
        name: "2seater",
        type: "number",
        label: "No. of 2 seater",
        required: false,
        value: "",
        min: 0,
        validation: true,
      },
      {
        name: "3seater",
        type: "number",
        label: "No. of 3 seater",
        required: false,
        value: "",
        min: 0,
        validation: true,
      },
      {
        name: "smallCornerCouch",
        type: "number",
        label: "No. of Small corner couch",
        required: false,
        value: "",
        min: 0,
        validation: true,
      },
      {
        name: "largeCornerCouch",
        type: "number",
        label: "No. of Large corner couch",
        required: false,
        value: "",
        min: 0,
        validation: true,
      },
      {
        name: "armchair",
        type: "number",
        label: "No. of Armchair",
        required: false,
        value: "",
        min: 0,
        validation: true,
      },
      {
        name: "stool",
        type: "number",
        label: "No. of stool",
        required: false,
        value: "",
        min: 0,
        validation: true,
      },
      {
        name: "chairWithBackrest",
        type: "number",
        label: "No. of Chair with backrest",
        required: false,
        value: "",
        min: 0,
        validation: true,
      },
      {
        name: "chairWithoutBackrest",
        type: "number",
        label: "No. of Chair without backrest",
        required: false,
        value: "",
        min: 0,
        validation: true,
      },
      {
        name: "individualCouch",
        type: "number",
        label: "No. of Individual couch",
        required: false,
        value: "",
        min: 0,
        validation: true,
      },
      {
        name: "degreePollution",
        type: "select",
        label: "Degree of pollution",
        required: true,
        value: "",
        options: ["light", "normal", "strong", "extreme"],
        min: 3,
        validation: true,
      },
      {
        name: "additionalInformation",
        type: "textarea",
        label: "Additional Information",
        required: false,
        value: "",
      },
    ],
  },
  {
    title: "Carpet Cleaning",
    name: "carpet-cleaning",
    inputs: [
      {
        name: "looseCarpet",
        type: "number",
        label: "Loose carpet (Total area in m²)",
        required: true,
        value: "",
        min: 0,
        validation: true,
      },
      {
        name: "fixedCarpet",
        type: "number",
        label: "Fixed carpet (Surface in m²)",
        required: true,
        value: "",
        min: 0,
        validation: true,
      },
      {
        name: "degreePollution",
        type: "select",
        label: "Degree of pollution",
        required: true,
        value: "",
        options: ["light", "normal", "strong", "extreme"],
        min: 3,
        validation: true,
      },
      {
        name: "additionalInformation",
        type: "textarea",
        label: "Additional Information",
        required: false,
        value: "",
      },
    ],
  },
  {
    title: "After Construction Cleaning",
    name: "after-cleaning",
    inputs: [
      {
        name: "areaInSqm",
        type: "number",
        label: "Area in sqm",
        required: true,
        value: "",
        min: 1,
        validation: true,
      },
      {
        name: "degreePollution",
        type: "select",
        label: "Degree of pollution",
        required: true,
        value: "",
        options: ["light", "normal", "strong", "extreme"],
        min: 3,
        validation: true,
      },
      {
        name: "additionalInformation",
        type: "textarea",
        label: "Additional Information",
        required: false,
        value: "",
      },
    ],
  },
  {
    title: "Billing Information",
    name: "billing",
    inputs: [
      {
        name: "date",
        type: "datetime",
        label: "Choose desired dates",
        required: false,
        value: null,
      },
      {
        name: "email",
        type: "email",
        label: "Email address",
        required: true,
        value: "",
        min: 3,
        validation: true,
      },
      {
        name: "phone",
        type: "text",
        label: "Mobile number",
        required: true,
        value: "",
        min: 3,
        validation: true,
      },
      {
        name: "company",
        type: "text",
        label: "Company",
        required: false,
        value: "",
      },
      {
        name: "gender",
        type: "select",
        label: "Select gender",
        required: true,
        value: "",
        options: ["Male", "Female", "Others"],
        min: 3,
        validation: true,
      },
      {
        name: "firstName",
        type: "text",
        label: "First name",
        required: true,
        value: "",
        min: 3,
        validation: true,
      },
      {
        name: "lastName",
        type: "text",
        label: "Last name",
        required: true,
        value: "",
        min: 3,
        validation: true,
      },
      {
        name: "street",
        type: "text",
        label: "Street",
        required: true,
        value: "",
        min: 3,
        validation: true,
      },
      {
        name: "streetNo",
        type: "text",
        label: "No.",
        required: true,
        value: "",
        min: 1,
        validation: true,
      },
      {
        name: "postcode",
        type: "text",
        label: "POSTCODE",
        required: true,
        value: "",
        min: 4,
        max: 6,
        validation: true,
      },
      {
        name: "city",
        type: "text",
        label: "City",
        required: true,
        value: "",
        min: 3,
        validation: true,
      },
    ],
  },
];

const Contact = () => {
  const { t } = useTranslation();
  const [user, setUser] = useState({});
  const [collapsedItems, setCollapsedItems] = useState([]);
  const [showError, setShowError] = useState(false);
  const [agree, setAgree] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(
    JSON.parse(JSON.stringify(defaultRequestObj)).filter(
      (i) => i.name === "billing"
    )
  );

  useEffect(() => {
    const wheelEvent = (event) => {
      if (document.activeElement.type === "number") {
        document.activeElement.blur();
      }
    };
    document.addEventListener("wheel", wheelEvent);
    return () => {
      document.removeEventListener("wheel", wheelEvent);
    };
  }, []);

  let name, value;

  // console.log("defaultRequestObj: ", defaultRequestObj);

  // const data = (e) => {
  //   name = e.target.name;
  //   value = e.target.value;
  //   setUser({ ...user, [name]: value });
  // };

  const onSubmit = async () => {
    setShowError(true);
    const errors = formData
      .map((i) => checkInputValidation(i))
      .reduce((acc, val) => acc + val, 0);
    if (!errors && agree && formData.length > 1) {
      setLoading(true);
      try {
        // Save data to Firebase
        const data = [
          ...formData.map((i) => ({
            title: i.title,
            name: i.name,
            data: i.inputs.map((j) => ({
              name: j.name,
              result: j.value,
            })),
          })),
        ];
        const res = await addDoc(colRef, {
          request: data,
        });
        let msg = `<div>`;
        formData.map((i) => {
          msg += `<h2>${i.title}</h2>`;
          msg += `<ul>`;
          i.inputs.map((j) => {
            msg += `<li>${camelCaseToText(j.name)}: ${j.value}</li>`;
          });
          msg += `</ul>`;
        });
        msg += `</div>`;

        // console.log("msg: ", msg);
        const billing = formData.find((i) => i.name === "billing");
        const reply_to = billing.inputs.find((i) => i.name === "email").value;
        const firstName = billing.inputs.find(
          (i) => i.name === "firstName"
        ).value;
        const lastName = billing.inputs.find(
          (i) => i.name === "lastName"
        ).value;
        const from_name = firstName + " " + lastName;
        emailjs
          .send(
            process.env.REACT_APP_EMAILJS_SERVICE_ID,
            process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
            { reply_to, from_name, message: msg },
            {
              publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
            }
          )
          .then(
            () => {
              console.log("SUCCESS!");
            },
            (error) => {
              console.log("FAILED...", error);
            }
          );
        setShowSuccessModal(true);
      } catch (error) {
        console.log("addDoc error: ", error);
      }
      setLoading(false);
      setFormData(
        JSON.parse(JSON.stringify(defaultRequestObj)).filter(
          (i) => i.name === "billing"
        )
      );
      setShowError(false);
      setAgree(false);
    }
  };

  const addService = (item) => {
    setFormData([item, ...formData]);
  };
  const removeService = (item) => {
    setCollapsedItems([...collapsedItems.filter((i) => i !== item.name)]);
    setFormData([...formData.filter((i) => i.name !== item.name)]);
  };

  const handleChange = (e, item, date = false) => {
    let newFormData = [...formData];
    if (date) {
      newFormData
        .find((i) => i.name === item.name)
        .inputs.find((j) => j.name === "date").value = e;
    } else
      newFormData
        .find((i) => i.name === item.name)
        .inputs.find((j) => j.name === e.target.name).value = e.target.value;
    setFormData([...newFormData]);
  };

  return (
    <>
      {showSuccessModal && (
        <div className='bg-[rgba(0,0,0,0.9)] fixed top-0 w-full h-[100vh] z-50 flex justify-center items-center'>
          <div className='w-[500px] bg-white h-[200px] rounded-md flex flex-col justify-center items-center absolute'>
            <h3 className='text-center mb-3 text-[25px] justify-items-start'>
              {t("Success Modal Heading")}🎉
            </h3>
            <p>{t("Success Modal P1")}</p>
            <p>{t("Success Modal P2")}</p>
            <button
              onClick={() => setShowSuccessModal(!showSuccessModal)}
              className='bg-[rgba(0,0,0,0.8)] text-white rounded-md mt-4 py-2 px-4 hover:bg-black'>
              {t("Close")}
            </button>
          </div>
        </div>
      )}
      <section
        method='Post'
        id='request'
        className='w-full bg-slate-200 flex flex-col lg:flex-row gap-10 lg:gap-20 h-fit p-4 lg:py-40 lg:px-20 justify-center items-center'>
        <div className='flex justify-center items-center w-full lg:w-[60%] lg:h-[600px] flex-col lg:flex-row bg-white rounded-lg px-8 py-6 gap-2 z-20'>
          <div className='flex justify-center items-start flex-col gap-4 lg:w-[250px] w-full lg:mb-0 mb-6'>
            <h1 className='flex items-center text-green-600 font-bold text-[20px] w-full lg:justify-start justify-center'>
              <IoAddCircle className='pr-1' size={30} /> {t("Add service")}
            </h1>
            <ul className='flex flex-col lg:justify-start justify-center w-full lg:items-start items-center'>
              {JSON.parse(JSON.stringify(defaultRequestObj))
                .filter((i) => i.name !== "billing")
                .map((j) => (
                  <li
                    key={j.name}
                    className={`list-none cursor-pointer py-4 border-b ${
                      formData.find((k) => k.name === j.name)
                        ? "text-gray-300 cursor-default"
                        : "hover:text-green-700"
                    }`}
                    onClick={() =>
                      !formData.find((k) => k.name === j.name) &&
                      addService({ ...j })
                    }>
                    {t(j.title)}
                  </li>
                ))}
            </ul>
          </div>

          <div className='gap-2 w-full h-[500px] overflow-y-auto relative'>
            {formData.map((i) => (
              <div
                key={i.name}
                className={`flex-col justify-center items-center gap-2 w-full border-2 rounded-lg mb-3 overflow-hidden ${
                  collapsedItems.find((j) => j === i.name)
                    ? "h-[44px]"
                    : "h-fit flex"
                } ${
                  showError && checkInputValidation(i)
                    ? "border-red-500 border"
                    : ""
                }`}>
                <div
                  className={`${
                    i.name !== "billing" && "flex justify-between"
                  } bg-gray-100 text-center items-center w-full p-2 text-green-500 font-bold text-[20px]`}>
                  {i.name !== "billing" && (
                    <>
                      {collapsedItems.find((j) => j === i.name) ? (
                        <IoCaretForwardSharp
                          onClick={() =>
                            setCollapsedItems([
                              ...collapsedItems.filter((k) => k !== i.name),
                            ])
                          }
                          size={25}
                          className='cursor-pointer hover:text-green-600'
                        />
                      ) : (
                        <IoCaretDownSharp
                          onClick={() =>
                            setCollapsedItems([...collapsedItems, i.name])
                          }
                          size={25}
                          className='cursor-pointer hover:text-green-600'
                        />
                      )}
                    </>
                  )}
                  {t(i.title)}
                  {i.name !== "billing" && (
                    <IoTrashBinSharp
                      onClick={() => removeService({ ...i })}
                      size={20}
                      className='cursor-pointer hover:text-red-500'
                    />
                  )}
                </div>
                <div className='flex flex-col justify-center items-center gap-2 w-full px-2'>
                  {i.inputs.map((j) =>
                    j.type === "text" ||
                    j.type === "number" ||
                    j.type === "email" ? (
                      <>
                        <input
                          key={i.name}
                          type={j.type}
                          name={j.name}
                          placeholder={`${t(j.label)}${j.required ? "*" : ""}`}
                          value={j.value}
                          className={`px-2 py-2 w-full border-green-50 rounded-lg text-[16px]
                          bg-slate-100 focus:outline-none focus:border-green-600 ${
                            showError &&
                            j.validation &&
                            validateInputs(j).length &&
                            "border-red-500 border focus:border-red-500"
                          }`}
                          onChange={(e) => handleChange(e, i)}
                        />
                        {showError && (
                          <>
                            {validateInputs(j).map((err, index) => (
                              <span
                                key={index}
                                className=' self-start text-red-500 text-[12px]'>
                                {t(err)}
                              </span>
                            ))}
                          </>
                        )}
                      </>
                    ) : j.type === "select" ? (
                      <>
                        <select
                          key={j.name}
                          name={j.name}
                          value={j.value}
                          className={`px-2 py-2 w-full border-green-50 rounded-lg text-[16px]
                          bg-slate-100 focus:outline-none focus:border-green-600 cursor-pointer ${
                            showError &&
                            j.validation &&
                            validateInputs(j).length &&
                            "border-red-500 border focus:border-red-500"
                          }`}
                          onChange={(e) => handleChange(e, i)}>
                          <option value='' disabled selected>
                            {`${t(j.label)}*`}
                          </option>
                          {j.options.map((k) => (
                            <option value={k}>{t(k)}</option>
                          ))}
                        </select>
                        {showError && j.validation && (
                          <>
                            {validateInputs(j).map((err, index) => (
                              <span
                                key={index}
                                className=' self-start text-red-500 text-[12px]'>
                                {t(err)}
                              </span>
                            ))}
                          </>
                        )}
                      </>
                    ) : j.type === "datetime" ? (
                      <DatePicker
                        key={j.name}
                        className='px-2 py-2 w-full border-green-50 rounded-lg text-[16px]
                        bg-slate-100 focus:outline-none focus:border-green-600 cursor-pointer'
                        selectedDates={j.value}
                        onChange={(date) => {
                          handleChange(date, i, true);
                        }}
                        minDate={new Date()}
                        placeholderText={t(j.label)}
                        showPopperArrow={false}
                        selectsMultiple
                        isClearable
                        showIcon
                      />
                    ) : (
                      <textarea
                        key={j.name}
                        name={j.name}
                        value={j.value}
                        className='px-2 py-2 w-full border-green-50 rounded-lg text-[16px]
                      bg-slate-100 focus:outline-none focus:border-green-600'
                        id=''
                        cols='30'
                        rows='5'
                        placeholder={t(j.label)}
                        onChange={(e) => handleChange(e, i)}></textarea>
                    )
                  )}
                </div>
              </div>
            ))}

            {showError && formData.length === 1 && (
              <div className='text-red-500 text-[12px] mb-4'>
                {t("Add at least one service")}
              </div>
            )}

            <span className='flex justify-center items-center text-[12px] leading-none mb-4 gap-2'>
              <input
                className='w-[25px] h-[25px]'
                type='checkbox'
                name='agree'
                onClick={() => setAgree(!agree)}
                value={agree}
                checked={agree}
                id='agree'
              />
              <span className={`${showError && !agree ? "text-red-500" : ""}`}>
                {t("I agree")}{" "}
                <Link
                  className='text-green-600 hover:text-green-800'
                  to='/terms'>
                  {t("terms and conditions")}
                </Link>{" "}
                {t("and")}{" "}
                <Link
                  className='text-green-600 hover:text-green-800'
                  to='/terms'>
                  {t("data protection declaration")}
                </Link>
                .
              </span>
            </span>

            <button
              onClick={onSubmit}
              className='bg-green-700 text-white px-4 py-3 w-full rounded-lg hover:bg-black cursor-pointer sticky bottom-0'>
              {loading ? (
                <ThreeDots
                  visible={true}
                  height='25'
                  width='25'
                  color='#ffffff'
                  radius='9'
                  ariaLabel='three-dots-loading'
                  wrapperStyle={{}}
                  wrapperClass='flex justify-center items-center'
                />
              ) : (
                t("Request Quote")
              )}
            </button>
          </div>
        </div>
      </section>
      {/* <img
        src={cube}
        alt=""
        className="w-full g-72 absolutetop-[3550px] hidden xl:block"
      /> */}
    </>
  );
};

export default Contact;
