import { download, logo, logo1, logo2 } from "../assets/icons";
import client1 from "../assets/images/client1.png";
import client2 from "../assets/images/client2.png";
import client3 from "../assets/images/client3.png";
import client4 from "../assets/images/client4.png";

export const aboutgrids = [
  {
    icon: download,
    heading: "Window Cleaning",
    text: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur",
    label: "View More",
  },
  {
    icon: logo,
    heading: "General Cleaning",
    text: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur",
    label: "View More",
  },
  {
    icon: logo1,
    heading: "Upholstery Cleaning",
    text: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur",
    label: "View More",
  },
  {
    icon: logo2,
    heading: "Carpet Cleaning",
    text: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur",
    label: "View More",
  },
];

export const clients = [
  {
    image: client1,
    about: "Testimonial 1",
    name: "Hans Müller",
    profile: "Web Developer",
  },

  {
    image: client2,
    about: "Testimonial 2",
    name: "Katrin Schneider",
    profile: "Android Developer",
  },

  {
    image: client3,
    about: "Testimonial 3",
    name: "Lukas Fischer",
    profile: "UI/UX Designer",
  },

  {
    image: client4,
    about: "Testimonial 4",
    name: "Sabine Weber",
    profile: "Full Stack Developer",
  },
];

export const pricing = [
  // {
  //   plan: "Starter Plan",
  //   price: "Free",
  //   label: "Proceed Free",
  //   about: "free cleaning for all third clean",
  // },

  {
    plan: "Yearly Plan",
    price: "$9/month",
    label: "Proceed Anually",
    about: "for all registered members",
  },

  {
    plan: "Monthly Plan",
    price: "$12/month",
    label: "Proceed Monthly",
    about: "for all esteemed partners",
  },
];
