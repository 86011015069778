import React from "react";
import Layout from "../Layout";
import { useTranslation } from "react-i18next";

const Terms = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <div>
        <body class='bg-gray-100 text-gray-800 max-w-xl mx-auto'>
          <div class='container mx-auto py-8 px-4'>
            <h2 class='text-green-600 text-3xl text-center font-bold mb-6'>
              {t("terms and conditions")}
            </h2>
            <p class='mb-4'>{t("Terms content")}</p>
            <h3 class='text-green-600 text-2xl font-bold mt-6 mb-4'>
              1. {t("Terms H1")}
            </h3>
            <p class='mb-4'>1.0 {t("Terms P1")}</p>
            <h3 class='text-green-600 text-2xl font-bold mt-6 mb-4'>
              2.0 {t("Terms H2")}
            </h3>
            <p class='mb-4'>2.1 {t("Terms P2")}</p>
            <h3 class='text-green-600 text-2xl font-bold mt-6 mb-4'>
              3. {t("Terms H3")}
            </h3>
            <p class='mb-4'>3.1 {t("Terms P3.1")}</p>

            <p class='mb-4'>3.2 {t("Terms P3.2")}</p>
            <h3 class='text-green-600 text-2xl font-bold mt-6 mb-4'>
              4.0 {t("Terms H4")}
            </h3>
            <p class='mb-4'>4.1 {t("Terms P4.1")}</p>
            <p class='mb-4'>4.2 {t("Terms P4.2")}</p>
            <p class='mb-4'>4.3 {t("Terms P4.3")}</p>

            {/* <!-- Include other sections of terms and conditions with appropriate Tailwind CSS classes --> */}
            <h3 class='text-green-600 text-2xl font-bold mt-6 mb-4'>
              5.0 {t("Terms H5")}
            </h3>
            <p>5.1 {t("Terms P5.1")}</p>
            <p>5.2 {t("Terms P5.2")}</p>
            <h3 class='text-green-600 text-2xl font-bold mt-6 mb-4'>
              6.0 {t("Terms H6")}
            </h3>
            <p class='mb-4'>6.1 {t("Terms P6")}</p>
            <h3 class='text-green-600 text-2xl font-bold mt-6 mb-4'>
              7.0 {t("Terms H7")}
            </h3>
            <p class='mb-4'>7.1 {t("Terms P7")}</p>
          </div>
        </body>
      </div>
    </Layout>
  );
};

export default Terms;
