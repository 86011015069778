import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import App from "./App";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import de from "./translations/de-DE.json";
import en from "./translations/en-GB";

i18next.init({
  interpolation: { escapeValue: false },
  lng: localStorage.getItem("i18nextLng") || "de",
  resources: {
    en: {
      translation: en,
    },
    de: {
      translation: de,
    },
  },
});

const publicURL = process.env.REACT_APP_PUBLIC_URL;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router basename={publicURL}>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </Router>
  </React.StrictMode>
);
