import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { IoCall } from "react-icons/io5";
import { logo } from "../assets/icons";
import { useTranslation } from "react-i18next";
import { DE, GB } from "country-flag-icons/react/3x2";

const Header = () => {
  const { t, i18n } = useTranslation();
  const [toggle, setToggle] = useState(false);
  const [iconToggle, setIconToggle] = useState(false);
  const [menuClass, setMenuClass] = useState("");

  const togglemenu = () => {
    setToggle(!toggle);
  };

  const toggleIcon = () => {
    setIconToggle(!iconToggle);
  };
  useEffect(() => {
    setMenuClass(
      toggle
        ? "transition-opacity ease-in-out duration-300 transform translate-y-0 opacity-100 "
        : "transition-opacity ease-in-out duration-300 transform -translate-y-full opacity-0"
    );
  }, [toggle]);

  const translate = () => {
    if (!localStorage.getItem("i18nextLng")) {
      localStorage.setItem("i18nextLng", "de");
      i18n.changeLanguage("de");
    } else if (localStorage.getItem("i18nextLng") === "en") {
      localStorage.setItem("i18nextLng", "de");
      i18n.changeLanguage("de");
    } else {
      localStorage.setItem("i18nextLng", "en");
      i18n.changeLanguage("en");
    }
  };

  return (
    <section className='w-full bg-black text-white flex justify-between items-center px-8 py-6 lg:px-16 sticky top-0 z-40'>
      <Link to='/'>
        <img
          src={logo}
          alt='icon'
          width={65}
          height={65}
          className='mt-2 me-4 cursor-pointer'
        />
      </Link>
      <div className='hidden lg:flex justify-end items-center gap-2'>
        <ul className='flex justify-center items-center gap-3'>
          <Link to='/'>
            <li className='text-lg text-slate-100 cursor-pointer rounded-sm px-5 py-2 hover:bg-green-600 hover:text-white active:text-white'>
              {t("Home")}
            </li>
          </Link>

          <li>
            <a
              className='text-lg text-slate-100 cursor-pointer rounded-sm px-5 py-2  hover:bg-green-600 hover:text-white active:text-white'
              href='/services'>
              {t("Services")}
            </a>
          </li>

          <li>
            <a
              className='text-lg text-slate-100 cursor-pointer rounded-sm px-5 py-2  hover:bg-green-600 hover:text-white active:text-white'
              href='/about-us'>
              {t("About")}
            </a>
          </li>

          <li>
            <a
              href='https://wa.me/+4916096549232'
              target='_blank'
              rel='noreferrer'
              className='cursor-pointer hover:text-green-600 flex items-center gap-2 text-lg text-slate-100'>
              <IoCall /> (+49) 160 965 49232
            </a>
          </li>

          <li>
            <span
              className='flex h-[60px] w-[60px] text-slate-100 cursor-pointer rounded-sm px-5 hover:text-green-600 active:text-white'
              onClick={translate}>
              {!localStorage.getItem("i18nextLng") ? (
                <GB title='English' className='' />
              ) : localStorage.getItem("i18nextLng") === "de" ? (
                <GB title='English' className='' />
              ) : (
                <DE title='German' className='' />
              )}
            </span>
          </li>
        </ul>
      </div>

      <div className='flex lg:hidden flex-col cursor-pointer'>
        {iconToggle ? (
          <IoIosCloseCircleOutline
            onClick={() => {
              togglemenu();
              toggleIcon();
            }}
          />
        ) : (
          <AiOutlineMenu
            onClick={() => {
              togglemenu();
              toggleIcon();
            }}
          />
        )}
        {toggle && (
          <div
            id='mob-menu'
            className={`bg-black text-white py-4 absolute top-20 right-0 w-full ${menuClass}`}>
            <ul className='flex flex-col justify-center items-start gap-2'>
              <li className='text-xl text-white font-semibold hover:bg-green-600 hover:text-white w-full py-3 text-center'>
                <Link to='/'>{t("Home")}</Link>
              </li>

              <li className='text-xl text-white font-semibold hover:bg-green-600 hover:text-white w-full py-3 text-center'>
                <Link to='/services'>{t("Services")}</Link>
              </li>

              <li className='text-xl text-white font-semibold hover:bg-green-600 hover:text-white w-full py-3 text-center flex justify-center items-center'>
                <Link to='/about-us'>{t("About")}</Link>
              </li>

              <a
                href='https://wa.me/+4916096549232'
                target='_blank'
                rel='noreferrer'
                className='text-xl text-white font-semibold w-full py-3 text-center flex gap-2 justify-center items-center'>
                <IoCall color='white' /> (+49) 160 965 49232
              </a>

              <span
                className='w-[40px] absolute top-[-44px] right-[60px] z-50 text-slate-100 cursor-pointer rounded-sm px-3 py-2 hover:text-green-600 active:text-white'
                onClick={translate}>
                {!localStorage.getItem("i18nextLng") ? (
                  <GB title='English' className='' />
                ) : localStorage.getItem("i18nextLng") === "de" ? (
                  <GB title='English' className='' />
                ) : (
                  <DE title='German' className='' />
                )}
              </span>
            </ul>
          </div>
        )}
      </div>
    </section>
  );
};

export default Header;
