import React from "react";
import { Link } from "react-router-dom";
import window from "../assets/images/window.png";
import general from "../assets/images/general.png";
import furniture from "../assets/images/furniture.jpg";
import carpet from "../assets/images/carpet.jpg";
import after from "../assets/images/after.png";
import { useTranslation } from "react-i18next";

const Services = () => {
  const { t } = useTranslation();

  return (
    <section
      id='services'
      className='w-full flex flex-col gap-5 h-fit bg-slate-100 p-7 lg:p-20 lg:pb-40'>
      <h1 className='flex flex-col py-8 text-green-600 font-bold text-3xl text-center leading-[68px]'>
        {t("Our Services")}
      </h1>

      <div className='flex justify-center items-center flex-wrap mt-5 w-full gap-20 cursor-pointer'>
        <Link to='/services/general-cleaning'>
          <div className='flex flex-col justify-center items-center gap-5 hover:text-green-500'>
            <div className='w-60 h-60'>
              <img
                src={general}
                alt='icon'
                className='h-full w-full object-cover bg-gray-400 hover:bg-green-500 p-2 rounded-full cursor-pointer'
              />
            </div>
            <h2 className=''>{t("General Cleaning")}</h2>
          </div>
        </Link>

        <Link to='/services/window-cleaning'>
          <div className='flex flex-col justify-center items-center gap-5 hover:text-green-500'>
            <div className='w-60 h-60'>
              <img
                src={window}
                alt='icon'
                className='h-full w-full object-cover bg-gray-400 hover:bg-green-500 p-2 rounded-full cursor-pointer'
              />
            </div>

            <h2 className=''>{t("Window Cleaning")}</h2>
          </div>
        </Link>

        <Link to='/services/furniture-cleaning'>
          <div className='flex flex-col justify-center items-center gap-5 hover:text-green-500'>
            <div className='w-60 h-60'>
              <img
                src={furniture}
                alt='icon'
                className='h-full w-full object-cover bg-gray-400 hover:bg-green-500 p-2 rounded-full cursor-pointer'
              />
            </div>
            <h2 className=''>{t("Furniture Cleaning")}</h2>
          </div>
        </Link>

        <Link to='/services/carpet-cleaning'>
          <div className='flex flex-col justify-center items-center gap-5 hover:text-green-500'>
            <div className='w-60 h-60'>
              <img
                src={carpet}
                alt='icon'
                className='h-full w-full object-cover bg-gray-400 hover:bg-green-500 p-2 rounded-full cursor-pointer'
              />
            </div>
            <h2 className=''>{t("Carpet Cleaning")}</h2>
          </div>
        </Link>
        <Link to='/services/after-cleaning'>
          <div className='flex flex-col justify-center items-center gap-5 hover:text-green-500'>
            <div className='w-60 h-60'>
              <img
                src={after}
                alt='icon'
                className='h-full w-full object-cover bg-gray-400 hover:bg-green-500 p-2 rounded-full cursor-pointer'
              />
            </div>

            <h2 className=''>{t("After Construction Cleaning")}</h2>
          </div>
        </Link>
      </div>
    </section>
  );
};

export default Services;
