import React from "react";
import {
  useNavigate,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";

import HomePage from "./page/home";
import Services from "./page/services";
import AboutUs from "./page/aboutus";
import Support from "./page/support";
import Terms from "./page/terms";

const App = () => {
  return (
    <Routes>
      <Route path='/' element={<HomePage />} />
      <Route
        exact
        path='/services/'
        element={<Navigate to='/services/general-cleaning/' replace />}
      />
      <Route path='/services/:id' element={<Services />} exact />
      <Route path='/about-us' element={<AboutUs />} exact />
      <Route path='/support' element={<Support />} exact />
      <Route path='/terms' element={<Terms />} exact />
    </Routes>
  );
};

export default App;
