import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import happy from "../assets/images/home.png";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation();

  return (
    <section className='flex flex-col lg:flex-row justify-between items-center gap-4 w-full bg-slate-100 h-auto'>
      <div className='flex justify-center items-start flex-col gap-8 lg:w-1/2 px-10 py-10 lg:px-20 lg:py-20'>
        <h1 className='text-green-600 font-bold text-3xl'>{t("Home Title")}</h1>
        <p className='text-slate-90 text-1xl'>{t("Home Intro")}</p>
        <div className='flex justify-center gap-7'>
          <HashLink smooth to='/#request'>
            <button className='bg-green-800 text-white px-4 lg:px-8 py-3 rounded-md text-[18px] hover:bg-black hover:text-white cursor-pointer'>
              {t("Request Now")}
            </button>
          </HashLink>
        </div>
      </div>
      <div className='flex justify-center items-center w-1/2 py-20 object-cover'>
        <img src={happy} alt='hero' />
      </div>
    </section>
  );
};

export default Home;
