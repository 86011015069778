import React from "react";
import Layout from "../Layout";
import aboutUs from "../assets/images/about-us.png";
import { useTranslation } from "react-i18next";

const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <div className='flex lg:flex-row flex-col'>
        {/* Image Column */}
        <div className='lg:w-1/2 w-full lg:h-screen h-[400px] overflow-hidden lg:sticky lg:top-0'>
          <img
            src={aboutUs}
            alt='my profile'
            className='w-full h-full object-cover'
          />
        </div>

        {/* Content Column */}
        <div className='lg:w-1/2 w-full overflow-y-auto px-10 py-10 lg:px-20 lg:py-10'>
          <h1 className='text-green-600 font-bold text-2xl py-4'>
            {t("About Heading1")}
          </h1>
          <h2>{t("About P1")}</h2>
          {/* Services */}
          <div>
            <h1 className='text-gray-600 font-bold text-2xl hover:text-green-600 py-4'>
              {t("About Heading2")}
            </h1>
            <p>{t("About P2")}</p>
            <ul className='list-disc pl-6 '>
              <li>{t("About P2 LI1")}</li>
              <li>{t("About P2 LI2")}</li>
              <li>{t("About P2 LI3")}</li>
              <li>{t("About P2 LI4")}</li>
            </ul>
          </div>
          {/* Process */}
          <div>
            <h1 className='text-gray-600 font-bold text-2xl hover:text-green-600 py-4'>
              {t("About Heading3")}
            </h1>
            <p>{t("About P3")}</p>
            <ol className='list-decimal pl-6'>
              <li>{t("About P3 LI1")}</li>
              <li>{t("About P3 LI2")}</li>
              <li>{t("About P3 LI3")}</li>
              <li>{t("About P3 LI4")}</li>
              <li>{t("About P3 LI5")}</li>
            </ol>
          </div>
          {/* Why Choose Us */}
          <div>
            <h1 className='text-gray-600 font-bold text-2xl hover:text-green-600 py-4'>
              {t("About Heading4")}
            </h1>
            <p>{t("About P4")}</p>
          </div>
          {/* Get Started */}
          <div>
            <h1 className='text-gray-600 font-bold text-2xl hover:text-green-600 py-4'>
              {t("About Heading5")}
            </h1>
            <p>{t("About P5")}</p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AboutUs;
