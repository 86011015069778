import React from "react";
import About from "../sections/About";
import Contact from "../sections/Contact";
import Home from "../sections/Home";
import Testimonials from "../sections/Testimonials";
import Services from "../sections/Services";
import Layout from "../Layout";

const HomePage = () => {
  return (
    <Layout>
      <Home />
      <Services />
      <Testimonials />
      <Contact />
    </Layout>
  );
};

export default HomePage;
