import React from "react";
import { clients } from "../exports";
import ClientGrid from "../Components/ClientGrid";
import { useTranslation } from "react-i18next";

const Testimonials = () => {
  const { t } = useTranslation();

  return (
    <section
      id='testimonials'
      className='w-full flex flex-col gap-5 h-fit bg-slate-100 p-7 lg:p-20 lg:pb-40'>
      <p className='text-green-600 font-bold text-center text-3xl'>
        {t("Testimonials")}
      </p>
      <h1 className='text-green-600 text-2xl text-center leading-[68px]'>
        {t("What our clients are saying")}
      </h1>
      {/* <p className="text-slate-950 text-2xl text-center"> */}
      {/* Lorem ipsum, dolor sit amet consectetur adipisicing elit. Magni esse
        suscipit natus <br /> facilis aspernatur libero perspiciatis eos
        eligendi. */}
      {/* </p> */}
      <div className='flex justify-center items-center flex-wrap mt-5 w-full gap-6'>
        {clients.map((client) => (
          <div key={clients.name} className='w-64'>
            {<ClientGrid t={t} {...client} />}
          </div>
        ))}
      </div>
    </section>
  );
};

export default Testimonials;
