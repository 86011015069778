import React from "react";
import { CgProfile } from "react-icons/cg";

const ClientGrid = ({ t, image, about, name, profile }) => {
  return (
    <div className='bg-slate-200 px-6 py-8 flex justify-center items-center flex-col gap-4 rounded-xl'>
      <CgProfile />
      <p className='text-center text-[17px] text-gray-600'>{t(about)}</p>
      <h1 className='text-center text-green-700 text-1xl font-semibold'>
        {name}
      </h1>
      {/* <p className='text-center text-[17px] text-gray-800'>{profile}</p> */}
    </div>
  );
};

export default ClientGrid;
