import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import Layout from "../Layout";
import servicesData from "../exports/services";
import windowImage from "../assets/images/window-bg.jpg";
import furnitureImage from "../assets/images/furniture2.jpg";
import generalImage from "../assets/images/general-bg.png";
import carpetImage from "../assets/images/carpet2.png";
import afterImage from "../assets/images/after2.jpeg";
import { useTranslation } from "react-i18next";

const services = {
  "general-cleaning": { title: "General Cleaning", image: generalImage },
  "window-cleaning": { title: "Window Cleaning", image: windowImage },
  "furniture-cleaning": { title: "Furniture Cleaning", image: furnitureImage },
  "carpet-cleaning": { title: "Carpet Cleaning", image: carpetImage },
  "after-cleaning": { title: "After Cleaning", image: afterImage },
};

const Services = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [service, setService] = useState(null);
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!services[id])
      navigate("/services/general-cleaning/", { replace: true });
    setService(servicesData[id]);
    // setLoading(true);
  }, [id]);

  return (
    <>
      {service && (
        <Layout>
          <div className='flex lg:flex-row flex-col gap-4 justify-between w-11/12 m-auto relative'>
            <ul className='flex flex-col lg:w-3/12 w-full mt-4 sticky'>
              <Link to='/services/general-cleaning'>
                <li
                  className={`list-none cursor-pointer p-4 border-b ${
                    id === "general-cleaning"
                      ? "bg-green-700 text-white"
                      : "hover:text-green-700"
                  }`}>
                  {t("General Cleaning")}
                </li>
              </Link>
              <Link to='/services/window-cleaning'>
                <li
                  className={`list-none cursor-pointer p-4 border-b ${
                    id === "window-cleaning"
                      ? "bg-green-700 text-white"
                      : "hover:text-green-700"
                  }`}>
                  {t("Window Cleaning")}
                </li>
              </Link>
              <Link to='/services/furniture-cleaning'>
                <li
                  className={`list-none cursor-pointer p-4 border-b ${
                    id === "furniture-cleaning"
                      ? "bg-green-700 text-white"
                      : "hover:text-green-700"
                  }`}>
                  {t("Furniture Cleaning")}
                </li>
              </Link>
              <Link to='/services/carpet-cleaning'>
                <li
                  className={`list-none cursor-pointer p-4 border-b ${
                    id === "carpet-cleaning"
                      ? "bg-green-700 text-white"
                      : "hover:text-green-700"
                  }`}>
                  {t("Carpet Cleaning")}
                </li>
              </Link>
              <Link to='/services/after-cleaning'>
                <li
                  className={`list-none cursor-pointer p-4 border-b ${
                    id === "after-cleaning"
                      ? "bg-green-700 text-white"
                      : "hover:text-green-700"
                  }`}>
                  {t("After Construction Cleaning")}
                </li>
              </Link>
            </ul>
            <div className='w-full'>
              <div
                className='h-[400px] w-full bg-no-repeat bg-cover bg-center'
                style={{
                  backgroundImage: `url(${services[id].image})`,
                }}></div>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    localStorage.getItem("i18nextLng") === "en"
                      ? service.content
                      : service.content_de,
                }}
              />
            </div>
          </div>
        </Layout>
      )}
    </>
  );
};

export default Services;
